import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import CustomerService from '../Services/CustomerService';
import CustomerStatus from '../Enums/CustomerStatus';

const CustomerSelect = ({ value, onChange, error }) => {
  const [clienteSelecionado, setClienteSelecionado] = useState(value || null);

  const carregarOpcoes = (inputValue, callback) => {
    const fetchClientes = inputValue
      ? CustomerService.getCustomerByName(inputValue)
      : CustomerService.getCustomers({ limit: 10 });

    fetchClientes
      .then((response) => {
        const options = response.data.map((cliente) => ({
          value: cliente.customer_id,
          label: `${cliente.name} / ${cliente.taxpayer_id}`,
          cliente,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Erro ao buscar clientes:', error);
        callback([]);
      });
  };

  const handleChange = (selecionado) => {
    const cliente = selecionado ? selecionado.cliente : null;
    setClienteSelecionado(cliente);
    
    if (onChange) {
      onChange(cliente);
    }
  };

  return (
    <div className="col-span-12 mt-5">
      <label className="block font-nunito font-medium text-sm leading-[18px] text-[#A1A5B7] mb-2">
        Cliente/Empresa:
      </label>
      <AsyncSelect
        cacheOptions
        loadOptions={carregarOpcoes}
        defaultOptions
        onChange={handleChange}
        value={value ? {
          value: value.customer_id,
          label: `${value.name} / ${value.taxpayer_id}`,
          cliente: value
        } : null}
        placeholder="Buscar cliente por nome"
        className="react-select-container mb-2"
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
      />
      {clienteSelecionado && (
        <div className="mt-2 text-[#3F4254]">
          {CustomerStatus[clienteSelecionado.status]}
        </div>
      )}
      {error && (
        <div className="text-red-500 text-sm mt-1">
          {error}
        </div>
      )}
    </div>
  );
};

export default CustomerSelect;
