// Este componente representa a criação de um pedido, com seleção de cliente, produtos/serviços, ciclos de pagamento, etc.
// Aqui, utilizamos bibliotecas como Formik para gerenciamento de formulários, Yup para validação, react-toastify para notificações, e SweetAlert2 para alertas.
// Além disso, dados de clientes, cupons, produtos, métodos de pagamento, etc., estão sendo simulados (fakes) para exemplificação.

import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import Badge from "../../components/Badge";
import Button from "../../components/Base/Button";
import { FormCheck } from "../../components/Base/Form";
import Lucide from "../../components/Base/Lucide";
import BrlCurrencyComponent from "../../components/Form/BrlCurrencyComponent";
import SelectBox from "../../components/Form/SelectBox";
import LoadingIcon from "../../components/Base/LoadingIcon";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import clsx from "clsx";
import { formatToBRL } from "../../utils/helpers";
import CustomerSelect from "../../pages/Customers/components/CustomerSelect";
import ProductService from "../../pages/Products/Services/ProductService";
import OrderService from "../../pages/Orders/Services/OrderService";
import { OrderStatus } from "../Orders/Enums/OrderStatus"
import { PaymentStatus } from "../Payments/Enums/PaymentStatus"
import { PaymentCycle } from "../Payments/Enums/PaymentCycle"

// Lista de ciclos de pagamento (em inglês e português)
export const periodicals = ["monthly", "quarterly", "semiannual", "yearly", "biennial", "triennial"];
export const periodicals_br = ["Mensal", "Trimestral", "Semestral", "Anual", "Bienal", "Trienal"];

const fakePaymentMethods = [
  { id: 1, description: "Cartão de Crédito" },
  { id: 2, description: "Boleto Bancário" },
];

const CreateOrder = () => {
  // Estados do componente
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productHoverId, setProductHoverId] = useState(null);
  const [productsById, setProductsById] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  // Hooks de roteamento
  const navigate = useNavigate();

  // Configuração do Formik
  const formik = useFormik({
    initialValues: {
      customer_id: null,
      generate_invoice: 1,
      send_email: 1,
      status: OrderStatus.PENDING,
      payment_status: PaymentStatus.PENDING,
      items: [
        {
          id: crypto.randomUUID(),
          product_id: "",
          name: "",
          qty: 1,
          price: "",
          overwrite: false,
          payment_cycle: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      customer_id: Yup.number().integer("O cliente é obrigatório"),
      items: Yup.array()
        .of(
          Yup.object().shape({
            product_id: Yup.string().required("Produto é obrigatório"),
            name: Yup.string().required("Nome do produto é obrigatório"),
            payment_cycle: Yup.string()
              .oneOf(["free", "one_time", "monthly", "quarterly", "semiannual", "yearly", "biennial", "triennial"])
              .required("Tipo de pagamento é obrigátorio"),
            price: Yup.number().when("payment_cycle", {
              is: (paymentCycleValue) => paymentCycleValue !== "free",
              then: (schema) => schema.required("O preço é obrigatório"),
              otherwise: (schema) => schema.nullable(),
            }),
          })
        )
        .min(1, "Pelo menos um produto deve ser adicionado"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        // Inicializa a variável para armazenar o preço total
        let totalPrice = 0.00;

        // Mapeia os itens e acumula o preço total
        const items = values.items.map(item => {
          totalPrice += item.price; // Acumula o preço de cada item

          const paymentCycleMap = {
            free: PaymentCycle.FREE,
            one_time: PaymentCycle.ONE_TIME,
            monthly: PaymentCycle.MONTHLY,
            quarterly: PaymentCycle.QUARTERLY,
            semiannual: PaymentCycle.SEMESTERLY,
            yearly: PaymentCycle.ANNUAL,
            biennial: PaymentCycle.BIENNIAL,
            triennial: PaymentCycle.TRIENNIAL,
          };
          
          // Utiliza o mapeamento ou define um valor padrão caso a chave não exista
          const payment_cycle = paymentCycleMap[item.payment_cycle] || PaymentCycle.UNKNOWN;

          return {
            product_id: item.product_id,
            next_due_date: '2024-09-22',
            qty: item.qty,
            price: item.price,
            status: 1,
            overwrite: item.overwrite,
            payment_cycle: payment_cycle,
            payment_method: null,
            quantity: 1,
            additional_description: ""
            // Se precisar adicionar algum cálculo específico por item, faça aqui
            // Por exemplo, se quiser o total por item (price * qty):
            // total_per_item: item.price * item.qty,
          };
        });

        // Cria o objeto orderData com o preço total calculado
        const orderData = {
          customer_id: values.customer_id,
          generate_invoice: values.generate_invoice,
          send_email: values.send_email,
          total: totalPrice, // Utiliza o preço total acumulado
          status: values.status,
          payment_status: values.payment_status,
          order_items: items,
        };

        const response = await OrderService.createOrder(orderData);

        // Opcional: Verificar a resposta do servidor
        if (response) {
          resetForm();
          navigate("/orders", { replace: true });
          Swal.fire({
            title: "Pedido criado com sucesso!",
            icon: "success",
            confirmButtonColor: "#3E97FF",
          });
        }
      } catch (error) {
        console.error("Erro ao criar o pedido:", error);
        toast.error("Erro ao criar o pedido.");
      }
    },
  });

  // Efeito para carregar dados iniciais
  useEffect(() => {
    (async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Buscando produtos (fakes ou não)
        const productResponse = await ProductService.getProducts({
          limit: 7,
          page: 1,
          orderBy: "name",
          orderDirection: "asc",
        });

        const fetchedProducts = productResponse.data;

        // Processando cada produto para adicionar opções de pagamento
        const processedProducts = fetchedProducts.map((productItem) => {
          productItem.select_options = [];

          if (productItem.type_payment === "free") {
            productItem.select_options.push({
              key: "free",
              value: 0,
              setup: 0,
              label: "Grátis",
            });
          } else if (productItem.type_payment === "one_time") {
            // Pagamento único
            productItem.select_options.push({
              label: "Única vez",
              key: "one_time",
              value: productItem.price.monthly,
              setup: productItem.price.monthly_installation_fee,
            });
          } else {
            // Ciclos periódicos
            if (productItem.price.monthly_is_enabled) {
              productItem.select_options.push({
                label: "Mensal",
                value: productItem.price.monthly,
                setup: productItem.price.monthly_installation_fee,
                key: "monthly",
              });
            }
            if (productItem.price.quarterly_is_enabled) {
              productItem.select_options.push({
                label: "Trimestral",
                value: productItem.price.quarterly,
                setup: productItem.price.quarterly_installation_fee,
                key: "quarterly",
              });
            }
            if (productItem.price.semiannual_is_enabled) {
              productItem.select_options.push({
                label: "Semestral",
                value: productItem.price.semiannual,
                setup: productItem.price.semiannual_installation_fee,
                key: "semiannual",
              });
            }
            if (productItem.price.yearly_is_enabled) {
              productItem.select_options.push({
                label: "Anual",
                value: productItem.price.yearly,
                setup: productItem.price.yearly_installation_fee,
                key: "yearly",
              });
            }
            if (productItem.price.biennial_is_enabled) {
              productItem.select_options.push({
                label: "Bienal",
                value: productItem.price.biennial,
                setup: productItem.price.biennial_installation_fee,
                key: "biennial",
              });
            }
            if (productItem.price.triennial_is_enabled) {
              productItem.select_options.push({
                label: "Trienal",
                value: productItem.price.triennial,
                setup: productItem.price.triennial_installation_fee,
                key: "triennial",
              });
            }
          }
          return productItem;
        });

        setProducts(processedProducts);
        setPaymentMethods(fakePaymentMethods);

        // Criando um map de produtos pelo ID
        const mappedProductsById = {};
        processedProducts.forEach((prod) => {
          mappedProductsById[prod.product_id] = prod;
        });
        setProductsById(mappedProductsById);

      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cálculo do total (preço + taxa de configuração)
  const [total_price, total_setup_fee] = formik.values.items.reduce(
    (accumulator, currentItem) => {
      const { product_id: currentProductId, payment_cycle: currentPaymentCycle } = currentItem;
      if (currentProductId && currentPaymentCycle) {
        const currentProduct = products.find((prod) => prod.product_id == currentProductId);
        if (currentProduct) {
          let setupFeeKey = "";
          if (currentPaymentCycle === "one_time") {
            setupFeeKey = "monthly_installation_fee";
          } else if (currentPaymentCycle === "free") {
            setupFeeKey = "free";
          } else {
            setupFeeKey = currentPaymentCycle + "_installation_fee";
          }

          const currentPrice = Number(currentItem.price || 0);
          const currentSetupFee = setupFeeKey === "free" ? 0 : Number(currentProduct.price[setupFeeKey] || 0);

          return [accumulator[0] + currentPrice, accumulator[1] + currentSetupFee];
        }
      }
      return accumulator;
    },
    [0, 0]
  );

  return isLoading ? (
    <div className="h-96 relative flex flex-col items-center justify-center">
      <LoadingIcon icon="three-dots" className="w-8 h-8" />
    </div>
  ) : (
    <form
      className="grid grid-cols-12 gap-8"
      onSubmit={(event) => {
        event.preventDefault();
        if (!formik.values.items.length) toast.warning("Você precisa adicionar um produto ao pedido.");
        formik.handleSubmit(event);
      }}
    >
      <section className="col-span-12 lg:col-span-6 w-full">
        <div className="flex items-center justify-between mb-5">
          <h6 className="text-lg font-medium">Detalhes do pedido</h6>
        </div>

        <div className="flex flex-col w-full gap-8">
          <div className="flex flex-col gap-3">
            <div className="relative">
              <CustomerSelect
                value={formik.values.customer}
                onChange={(selectedValue) => {
                  formik.setFieldValue("customer_id", selectedValue.customer_id);
                }}
                error={
                  formik.touched.customer?.label && formik.errors.customer?.label
                    ? formik.errors.customer?.label
                    : formik.touched.customer?.value && formik.errors.customer?.value
                }
              />
            </div>

            <SelectBox
              label="Método de Pagamento"
              placeholder="Selecione um cliente para visualizar seu método de pagamento"
              name="payment_method_id"
              disabled
              value={
                selectedCustomer?.payment_method_id
                  ? {
                      label: fakePaymentMethods.find((method) => method.id == selectedCustomer?.payment_method_id)
                        ?.description,
                      value: selectedCustomer?.payment_method_id,
                    }
                  : undefined
              }
              onChange={(selectedValue) => {
                formik.setFieldValue("payment_method_id", selectedValue);
              }}
              containerClassName="col-span-12 lg:col-span-6"
              error={(formik.touched.payment_method_id || undefined) && formik.errors.payment_method_id}
              allowClear
              options={[
                ...paymentMethods.map((method) => ({ label: method.description, value: method.id })),
              ]}
            />

            <div className="flex col-span-12 gap-5">
              <FormCheck className="mt-2 mr-1 mb-2">
                <FormCheck.Input
                  id="checkbox-switch-generate-invoice"
                  type="checkbox"
                  className="h-6 w-6"
                  checked={formik.values.generate_invoice}
                  name="generate_invoice"
                  onChange={(event) => formik.setFieldValue("generate_invoice", !!event.target.checked)}
                />
                <FormCheck.Label htmlFor="checkbox-switch-generate-invoice">
                  Gerar fatura
                </FormCheck.Label>
              </FormCheck>

              <FormCheck className="mt-2 mr-1 mb-2">
                <FormCheck.Input
                  id="checkbox-switch-send-email"
                  type="checkbox"
                  className="h-6 w-6"
                  checked={formik.values.send_email}
                  name="send_email"
                  onChange={(event) => formik.setFieldValue("send_email", !!event.target.checked)}
                />
                <FormCheck.Label htmlFor="checkbox-switch-send-email">
                  Enviar e-mail
                </FormCheck.Label>
              </FormCheck>
            </div>
          </div>

          <div className="col-span-6">
            <section className="flex flex-col col-span-6">
              <div className="mb-5">
                <h6 className="text-lg font-medium">Produtos/Serviços</h6>
              </div>
            </section>

            <ul className="flex flex-col col-span-6">
              {formik.values.items.map((currentItem, index) => (
                <li
                  onMouseOver={() => setProductHoverId(currentItem.product_id)}
                  onMouseLeave={() => setProductHoverId(null)}
                  key={currentItem.id}
                  className={clsx([
                    "border-dashed border-2 hover:border-primary transition-all rounded-xl p-4 relative mb-4",
                    index !== 0 && "mt-2",
                  ])}
                >
                  <div className="flex flex-col gap-3">
                    <SelectBox
                      label="Produto / Serviço:"
                      labelClass="min-w-[130px] mb-0"
                      name={`product_id_${index}`}
                      errorClass="ml-[140px] absolute translate-y-6"
                      animation="intro-x"
                      value={formik.values.items[index].product_id}
                      onChange={(selectedValue) => {
                        const chosenProduct = products.find((p) => p.product_id === selectedValue);
                        formik.setFieldValue(`items[${index}].product_id`, selectedValue);
                        formik.setFieldValue(`items[${index}].name`, chosenProduct ? chosenProduct.name : "");
                        formik.setFieldValue(`items[${index}].payment_cycle`, "");
                        formik.setFieldValue(`items[${index}].price`, "");
                        formik.setFieldValue(`items[${index}].overwrite`, false);
                      }}
                      containerClassName="col-span-12 lg:col-span-6 flex items-center gap-2 relative"
                      error={
                        formik.touched.items &&
                        formik.touched.items[index] &&
                        formik.touched.items[index].product_id &&
                        formik.errors.items &&
                        formik.errors.items[index] &&
                        formik.errors.items[index].product_id
                      }
                      allowClear
                      options={[
                        { label: "Selecione um produto/serviço", value: "" },
                        ...products.map((productItem) => ({ label: productItem.name, value: productItem.product_id })),
                      ]}
                    />

                    {formik.values.items[index].product_id && (
                      <SelectBox
                        label="Ciclo de Pagamento:"
                        name={`payment_cycle_${index}`}
                        animation="intro-x"
                        labelClass="min-w-[130px] mb-0"
                        value={formik.values.items[index].payment_cycle}
                        onChange={(selectedValue) => {
                          formik.setFieldValue(`items[${index}].payment_cycle`, selectedValue);

                          if (selectedValue !== "free" && selectedValue !== "") {
                            const chosenProduct = products.find(
                              (productElement) =>
                                productElement.product_id === formik.values.items[index].product_id
                            );
                            const chosenOption = chosenProduct?.select_options.find(
                              (optionElement) => optionElement.key === selectedValue
                            );
                            formik.setFieldValue(`items[${index}].price`, Number(chosenOption?.value || 0));
                          } else {
                            formik.setFieldValue(`items[${index}].price`, 0);
                          }
                        }}
                        containerClassName="col-span-12 lg:col-span-6 flex items-center gap-2"
                        error={
                          formik.touched.items &&
                          formik.touched.items[index] &&
                          formik.touched.items[index].payment_cycle &&
                          formik.errors.items &&
                          formik.errors.items[index] &&
                          formik.errors.items[index].payment_cycle
                        }
                        allowClear
                        options={[
                          { label: "Selecione uma opção", value: "" },
                          ...(products
                            .find((productElement) => productElement.product_id == formik.values.items[index].product_id)
                            ?.select_options.map((optionElement) => ({
                              label: optionElement.label,
                              value: optionElement.key,
                            })) || []),
                        ]}
                      />
                    )}

                    {formik.values.items[index].product_id &&
                      formik.values.items[index].payment_cycle &&
                      formik.values.items[index].payment_cycle !== "free" && (
                        <div>
                          <BrlCurrencyComponent
                            name=""
                            containerClassName="col-span-6 flex items-center gap-2"
                            label="Preço:"
                            disabled={!formik.values.items[index].overwrite}
                            labelClass="min-w-[130px] mb-0"
                            value={formik.values.items[index].price}
                            handleChange={(event, val) => {
                              formik.setFieldValue(`items[${index}].price`, Number(val));
                            }}
                          />
                          <FormCheck className="mr-2 ml-[140px] mt-3">
                            <FormCheck.Input
                              id={`overwrite_${index}`}
                              type="checkbox"
                              value={formik.values.items[index].overwrite}
                              name="overwrite"
                              onChange={(event) =>
                                formik.setFieldValue(`items[${index}].overwrite`, !!event.target.checked)
                              }
                              className="h-4 w-4"
                            />
                            <FormCheck.Label
                              htmlFor={`overwrite_${index}`}
                              className="text-xs ml-1"
                            >
                              Sobrescrever
                            </FormCheck.Label>
                          </FormCheck>
                        </div>
                      )}
                  </div>
                  <div
                    className="absolute w-10 h-10 rounded-full bg-white z-[9999] -end-5 border-[2px] cursor-pointer hover:text-red-400 hover:border-red-400 transition-all -top-5 flex items-center justify-center text-slate-400 hover:scale-110"
                    onClick={() => {
                      const updatedItems = [...formik.values.items];
                      updatedItems.splice(index, 1);
                      formik.setFieldValue("items", updatedItems);
                    }}
                  >
                    <Lucide icon="Trash" />
                  </div>
                </li>
              ))}

              <li
                className="border-dashed border-2 rounded-xl p-4 h-[182px] flex items-center justify-center text-gray-400 hover:border-primary hover:text-primary transition-all cursor-pointer"
                onClick={() => {
                  formik.setFieldValue("items", [
                    ...formik.values.items,
                    {
                      id: crypto.randomUUID(),
                      product_id: "",
                      name: "",
                      qty: 1,
                      price: "",
                      overwrite: false,
                      payment_cycle: "",
                    },
                  ]);
                }}
              >
                <div className="flex flex-col items-center justify-center">
                  <Lucide icon="PlusCircle" className="w-10 h-10" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="col-span-12 lg:col-span-6">
        <section className="flex flex-col col-span-6">
          <div className="mb-5 flex justify-between items-center">
            <h6 className="text-lg font-medium">Resumo do pedido</h6>
          </div>
        </section>

        <div className="col-span-6 box p-5">
          <ul className="flex flex-col gap-4 rounded-md">
            {formik.values.items.map((currentProduct, index) => {
              let productName = currentProduct.name || "Não definido...";
              let setupFee = 0;

              if (currentProduct.product_id && productsById[currentProduct.product_id]) {
                const productData = productsById[currentProduct.product_id];
                if (currentProduct.payment_cycle === "one_time") {
                  setupFee = productData.price.monthly_installation_fee || 0;
                } else if (currentProduct.payment_cycle !== "free" && currentProduct.payment_cycle) {
                  setupFee = productData.price[currentProduct.payment_cycle + "_installation_fee"] || 0;
                }
              }

              let badges = null;
              if (currentProduct.payment_cycle === "free") {
                badges = <Badge type="dark" text="Grátis" className="mr-2" />;
              } else if (currentProduct.payment_cycle === "one_time") {
                badges = <Badge type="success" text="Única vez" className="mr-2" />;
              } else if (currentProduct.payment_cycle) {
                const periodIndex = periodicals.indexOf(currentProduct.payment_cycle);
                if (periodIndex !== -1) {
                  badges = (
                    <>
                      <Badge type="info" text="Recorrente" className="mr-2" />
                      <Badge type="success" text={periodicals_br[periodIndex]} className="mr-2" />
                    </>
                  );
                }
              }

              let priceInfo = "";
              if (currentProduct.payment_cycle === "free") {
                priceInfo = "R$ 0";
              } else if (currentProduct.payment_cycle) {
                const displayPrice = currentProduct.price ? formatToBRL(currentProduct.price) : "R$ 0";
                const setupText = setupFee ? " + " + formatToBRL(setupFee) + " Taxa de configuração" : "";
                priceInfo = displayPrice + setupText;
              }

              return (
                <li
                  key={currentProduct.product_id + "_resume"}
                  className={clsx([
                    "relative transiton-all",
                    index !== 0 && "border-t pt-3",
                    productHoverId === currentProduct.product_id && "text-primary",
                  ])}
                >
                  <div className="flex items-center justify-between text-[15px] font-medium relative z-20">
                    <h6 className="m-0 mr-1">{productName}</h6>
                    <span className="m-0 text-end">{priceInfo}</span>
                  </div>
                  <div className="flex items-center justify-between mt-2 relative z-20">
                    <div className="flex items-center">{badges}</div>
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="pt-5 mt-5 border-t">
            <div className="flex">
              <div className="mr-auto">Subtotal</div>
              <div className="font-medium">{formatToBRL(total_price)}</div>
            </div>
            <div className="flex mt-4">
              <div className="mr-auto">Taxa de Setup</div>
              <div className="font-medium">{formatToBRL(total_setup_fee)}</div>
            </div>
            <div className="flex pt-4 mt-4 border-t border-slate-200/60 dark:border-darkmode-400">
              <div className="mr-auto text-base font-medium">Total</div>
              <div className="text-base font-medium">
                {formatToBRL(total_price + total_setup_fee)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end mt-4 mr-1">
          <Button type="submit" variant="primary">
            Enviar pedido
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateOrder;
