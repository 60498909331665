
export const PaymentCycle = {
    FREE: 0,
    ONE_TIME: 1,
    MONTHLY: 2,
    QUARTERLY: 3,
    SEMESTERLY: 4,
    ANNUAL: 5,
    BIENNIAL: 6,
    TRIENNIAL: 7,
}