import _ from "lodash";
import clsx from "clsx";
import { Fragment, useEffect, useRef, useState, useCallback } from "react";
import Button from "../../components/Base/Button";
import { FormInput } from "../../components/Base/Form";
import Lucide from "../../components/Base/Lucide";
import Tippy from "../../components/Base/Tippy";
import { Menu } from "../../components/Base/Headless";
import Table from "../../components/Base/Table";
import LoadingIcon from "../../components/Base/LoadingIcon";
import { Transition } from "@headlessui/react";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import Pagination from "../../components/Base/Pagination";
import CreateCustomerModal from "./Modals/CustomerAddModal";
import { useTranslation } from "react-i18next";
import { formatCnpjCpf, formatDate } from "../../utils/helpers";
import CustomerService from "./Services/CustomerService";
import { Link } from "react-router-dom";

// Constante para referência PT-BR
const PTBRREF = {
  business_name: "Empresa",
  name: "Cliente",
  cpf_cnpj: "CPF / CNPJ",
  email: "E-mail",
};

// Componente MenuSearch
const MenuSearch = (props) => {
  const {
    show,
    getCustomers,
    handleChangeDataInput,
    inputFilterData,
    clearFilters,
    close,
  } = props;
  const { t } = useTranslation();

  const menuClasses = clsx(
    "p-4 shadow-[0px_3px_20px_#0000000b] bg-white border-transparent rounded-md dark:bg-darkmode-600 dark:border-transparent translate-y-10 -mt-10 outline-none"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    close();
    getCustomers();
  };

  return (
    <Transition
      show={show}
      enter="transition duration-300 ease-out"
      enterFrom="transform scale-50 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-300 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <form onSubmit={handleSubmit} className={menuClasses}>
        <div>
          <div className="text-xs text-left">{t("000103")}</div>
          <FormInput
            autoFocus
            onChange={(e) => {
              e.stopPropagation();
              handleChangeDataInput("business_name", e.target.value);
            }}
            value={inputFilterData.business_name}
            type="text"
            className="flex-1 mt-2"
            formInputSize="sm"
            placeholder={t("000111")}
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">{t("000102")}</div>
          <FormInput
            onChange={(e) => handleChangeDataInput("name", e.target.value)}
            value={inputFilterData.name}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder={t("000112")}
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">{t("000074")}</div>
          <FormInput
            onChange={(e) => handleChangeDataInput("email", e.target.value)}
            value={inputFilterData.email}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="exemplo@gmail.com"
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">{t("000104")}</div>
          <FormInput
            onChange={(e) => {
              e.stopPropagation();
              handleChangeDataInput("cpf_cnpj", e.target.value);
            }}
            value={inputFilterData.cpf_cnpj}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="000.000.000-00"
          />
        </div>
        <div className="flex items-center mt-3">
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={() => {
              clearFilters();
              close();
            }}
            className="w-32 ml-auto"
          >
            {t("000113")}
          </Button>
          <Button type="submit" variant="primary" size="sm" className="w-32 ml-2">
            {t("000114")}
          </Button>
        </div>
      </form>
    </Transition>
  );
};

// Hook para detectar cliques fora de um elemento
const useOutsideClick = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleOutClick = (e) => {
      if (!ref.current?.contains(e.target)) callback();
    };
    document.addEventListener("mousedown", handleOutClick);
    return () => document.removeEventListener("mousedown", handleOutClick);
  }, [callback]);

  return ref;
};

// Componente PopOverMenu
const PopOverMenu = ({
  getCustomers,
  inputFilterData,
  someFilterActive,
  setInputFilterData,
  clearFilters,
  handleChangeDataInput,
}) => {
  const [isPopOverActive, setPopOverActive] = useState(false);
  const modalRef = useOutsideClick(() => setPopOverActive(false));
  const { t } = useTranslation();

  useUpdateEffect(() => {
    if (!isPopOverActive) getCustomers();
  }, [isPopOverActive, getCustomers]);

  return (
    <div className="w-full max-w-72 relative z-[100]" ref={modalRef}>
      <div
        className="!box cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setPopOverActive((prev) => !prev);
        }}
      >
        <div className="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          {someFilterActive() ? (
            <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
              <p className="text-slate-400/90 dark:placeholder:text-slate-500/80 opacity-0">
                {t("000109")}
              </p>
              <div className="flex items-center ms-auto overflow-x-hidden absolute w-60 translate-y-[11px] pb-3 overflow-x-scroll">
                {Object.keys(inputFilterData)
                  .filter((key) => inputFilterData[key] !== "")
                  .map((key) => (
                    <span
                      key={key}
                      className="inline-flex items-center whitespace-nowrap px-3 py-1 me-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300"
                    >
                      {PTBRREF[key]}: {inputFilterData[key]}
                    </span>
                  ))}
              </div>
              <Lucide
                icon="XCircle"
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilters();
                }}
                className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 text-danger"
              />
            </div>
          ) : (
            <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
              <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                {t("000109")}
              </p>
              <Lucide
                icon="Search"
                className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
              />
            </div>
          )}
        </div>
      </div>
      <div className="w-72 mt-1 absolute">
        <MenuSearch
          show={isPopOverActive}
          close={() => setPopOverActive(false)}
          getCustomers={getCustomers}
          handleChangeDataInput={handleChangeDataInput}
          inputFilterData={inputFilterData}
          clearFilters={clearFilters}
        />
      </div>
    </div>
  );
};

// Componente principal CustomersList
function CustomersList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 6,
    current_page: 1,
    last_page: 1,
    from: 0,
    to: 0,
  });
  const [isFilterSynchronizedDatabase, setFilterSynchronizedDatabase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomerModalActive, setCustomerModalActive] = useState(false);
  const [inputFilterData, setInputFilterData] = useState({
    name: "",
    business_name: "",
    email: "",
    cpf_cnpj: "",
  });
  const { t } = useTranslation();

  const someFilterActive = useCallback(
    () => Object.values(inputFilterData).some((val) => val !== ""),
    [inputFilterData]
  );

  const getCustomers = useCallback(
    async (filter = true) => {
      setIsLoading(true);
      const filters = someFilterActive() && filter ? inputFilterData : {};
      try {
        const response = await CustomerService.getCustomers({
          limit: pagination.per_page,
          page: currentPage,
          orderBy: "id",
          orderDirection: "desc",
          filters,
        });
        if (response.status === "success") {
          setCustomers(response.data);
          setPagination({
            total: response.pagination.total,
            per_page: response.pagination.per_page,
            current_page: response.pagination.current_page,
            last_page: response.pagination.last_page,
            from: response.pagination.from,
            to: response.pagination.to,
          });
        } else {
          setCustomers([]);
          setPagination({
            total: 0,
            per_page: pagination.per_page,
            current_page: 1,
            last_page: 1,
            from: 0,
            to: 0,
          });
        }
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
        setCustomers([]);
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, inputFilterData, pagination.per_page, someFilterActive]
  );

  const clearFilters = useCallback(async () => {
    setIsLoading(true);
    setInputFilterData({ name: "", business_name: "", email: "", cpf_cnpj: "" });
    await getCustomers(false);
    setIsLoading(false);
  }, [getCustomers]);

  const handleChangeDataInput = useCallback((key, value) => {
    setFilterSynchronizedDatabase(false);
    setInputFilterData((old) => ({ ...old, [key]: value }));
  }, []);

  const goToFirstPage = useCallback(() => {
    setCurrentPage(1);
  }, []);

  const goToLastPage = useCallback(() => {
    setCurrentPage(pagination.last_page);
  }, [pagination.last_page]);

  const goToPrevPage = useCallback(() => {
    if (pagination.current_page > 1) {
      setCurrentPage(pagination.current_page - 1);
    }
  }, [pagination.current_page]);

  const goToNextPage = useCallback(() => {
    if (pagination.current_page < pagination.last_page) {
      setCurrentPage(pagination.current_page + 1);
    }
  }, [pagination.current_page, pagination.last_page]);

  useEffect(() => {
    getCustomers();
  }, [currentPage, getCustomers]);

  return (
    <>
      <h2 className="text-lg font-medium intro-y flex items-center gap-1">
        {t("000083")}
        <div className="text-center">
          <Tippy
            className="hover:text-primary"
            content={t("000110")}
            options={{ theme: "light", placement: "right" }}
          >
            <Lucide icon="Info" className="w-4 h-4" />
          </Tippy>
        </div>
      </h2>

      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap z-10">
          <PopOverMenu
            isFilterSynchronizedDatabase={isFilterSynchronizedDatabase}
            setFilterSynchronizedDatabase={setFilterSynchronizedDatabase}
            getCustomers={getCustomers}
            handleChangeDataInput={handleChangeDataInput}
            inputFilterData={inputFilterData}
            someFilterActive={someFilterActive}
            setInputFilterData={setInputFilterData}
            clearFilters={clearFilters}
          />
          <div className="ms-auto flex items-center">
            <Button
              variant="primary"
              className="mr-2 shadow-md"
              onClick={() => setCustomerModalActive(true)}
            >
              <Lucide icon="Plus" className="hidden w-5 h-5 mr-2 sm:block" />
              {t("000100")}
            </Button>
          </div>
        </div>

        <div className="col-span-12 overflow-auto intro-y lg:overflow-visible relative z-10">
          <Table className="border-spacing-y-[10px] border-separate -mt-2 z-10">
            <Table.Thead>
              <Table.Tr>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000101")}
                </Table.Th>
                <Table.Th className="border-b-0 whitespace-nowrap">
                  {t("000102")}
                </Table.Th>
                <Table.Th className="border-b-0 whitespace-nowrap">
                  {t("000103")}
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000104")}
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000105")}
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000106")}
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000107")}
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  {t("000108")}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            {!isLoading && (
              <Table.Tbody>
                {customers.map((c) => (
                  <Table.Tr key={c.customer_id} className="intro-x">
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      #{c.customer_id}
                    </Table.Td>
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      <a href="#" className="font-medium whitespace-nowrap">
                        {`${c.name} ${c.last_name}`}
                      </a>
                      <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                        {c.email}
                      </div>
                    </Table.Td>
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      {c.business_name || "N/A"}
                    </Table.Td>
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 whitespace-nowrap">
                      {formatCnpjCpf(c.cpf_cnpj || "")}
                    </Table.Td>
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      0
                    </Table.Td>
                    <Table.Td className="box w-20 rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      {c.status ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                          {t("000115")}
                        </span>
                      ) : (
                        <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                          {t("000116")}
                        </span>
                      )}
                    </Table.Td>
                    <Table.Td className="box rounded-l-none rounded-r-none border-x-0 text-center shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600">
                      {formatDate(c.created_at)}
                    </Table.Td>
                    <Table.Td
                      className={clsx(
                        "box w-38 rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600",
                        "before:absolute before:inset-y-0 before:left-0 before:my-auto before:block before:h-8 before:w-px before:bg-slate-200 before:dark:bg-darkmode-400"
                      )}
                    >
                      <div className="flex items-center justify-center">
                        <Menu>
                          <Menu.Button as={Button} variant="outline-secondary">
                            <Lucide icon="Settings" className="w-4 h-4" />
                          </Menu.Button>
                          <Menu.Items className="w-48">
                            <Menu.Item as={Link} to={`/customers/${c.customer_id}`}>
                              <Lucide icon="Eye" className="w-4 h-4 mr-2" /> {t("000118")}
                            </Menu.Item>
                          </Menu.Items>
                        </Menu>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            )}
          </Table>

          {isLoading && (
            <div className="flex flex-col items-center justify-center w-full my-32 absolute">
              <LoadingIcon icon="three-dots" className="w-8 h-8" />
            </div>
          )}
        </div>

        {!isLoading && customers.length > 0 && (
          <div className="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap">
            <Pagination className="w-full sm:w-auto mx-auto">
              <Pagination.Link onClick={goToFirstPage} disabled={pagination.current_page === 1}>
                <Lucide icon="ChevronsLeft" className="w-4 h-4" />
              </Pagination.Link>
              <Pagination.Link onClick={goToPrevPage}>
                <Lucide icon="ChevronLeft" className="w-4 h-4" />
              </Pagination.Link>
              {Array.from({ length: pagination.last_page }, (_, index) => index + 1)
                .slice(
                  Math.max(0, pagination.current_page - 3),
                  Math.min(pagination.last_page, pagination.current_page + 2)
                )
                .map((page) => (
                  <Pagination.Link
                    key={page}
                    active={page === pagination.current_page}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Link>
                ))}
              <Pagination.Link onClick={goToNextPage}>
                <Lucide icon="ChevronRight" className="w-4 h-4" />
              </Pagination.Link>
              <Pagination.Link
                onClick={goToLastPage}
                disabled={pagination.current_page === pagination.last_page}
              >
                <Lucide icon="ChevronsRight" className="w-4 h-4" />
              </Pagination.Link>
            </Pagination>
          </div>
        )}
      </div>

      <CreateCustomerModal
        isModalOpen={isCustomerModalActive}
        setCustomers={setCustomers}
        closeModal={() => setCustomerModalActive(false)}
      />
    </>
  );
}

export default CustomersList;
