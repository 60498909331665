import { useState, useEffect } from "react";
import InvoiceService from "./Services/InvoiceService";
import { useParams } from "react-router-dom";

function InvoicesShow() {
  // States para guardar as invoices (caso precise exibir listas) e o body HTML/iframe/etc.
  const [body, setBody] = useState("");
  const { invoice_id } = useParams();

  // useEffect para chamar a API na montagem do componente
  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line
  }, []);

  // Função assíncrona para buscar as invoices
  const fetchInvoices = async () => {
    try {
      const response = await InvoiceService.showInvoice(invoice_id);
      if (response) {
        // Ajuste conforme a estrutura retornada pela sua API
        setBody(response.data.body ?? "Fatura não encontrada");
      }
    } catch (error) {
      console.error("Erro ao buscar invoices:", error);
    }
  };

  return (
    <div>
      {/* Exemplo: imprimir o body retornado */}
      <div dangerouslySetInnerHTML={{ __html: body }} />

      {/* Se quiser inspecionar as invoices em JSON, pode testar assim:
      <pre>{JSON.stringify(invoices, null, 2)}</pre> 
      */}
    </div>
  );
}

export default InvoicesShow;
