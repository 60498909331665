import { useEffect, useState, useCallback } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const pagesContent = {
  Dashboard: {
    title: "Dashboard",
    text: "Visualize, filtre e gerencie todas as informações do seu negócio.",
  },
};

const Home = () => {
  const location = useLocation();
  const [menuActive, setMenuActive] = useState("Clientes");
  const [content, setContent] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const page = capitalizeFirstLetter(
      location.pathname.substring(1).split("/")[0].toLowerCase()
    );
    setMenuActive(page);
    setContent(pagesContent[page] || pagesContent["Dashboard"]);
  }, [location]);

  // Dados do gráfico (exemplo de histórico de pagamentos)
  const chartData = {
    labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun"],
    datasets: [
      {
        label: "Pagamentos",
        data: [15, 20, 18, 25, 22, 30],
        borderColor: "rgba(75,192,192,1)",
        backgroundColor: "rgba(75,192,192,0.2)",
        fill: true,
        tension: 0.3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: "Histórico de Pagamentos" },
    },
  };

  return (
    <div className="bg-gray-100 p-4">
      <div className="max-w-7xl mx-auto">
        {/* Título e descrição */}
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold mb-2">
            {content.title || "Dashboard"}
          </h1>
          <p className="text-gray-600">
            {content.text ||
              "Visualize, filtre e gerencie todas as informações do seu negócio."}
          </p>
        </div>

        {/* Cards de resumo */}
        <div className="flex justify-center space-x-10">
          <div className="flex flex-col items-center p-6 bg-blue-100 rounded-lg shadow-lg mr-10">
            <span className="text-lg font-medium text-blue-800">Assinaturas Ativas</span>
            <span className="mt-2 text-4xl font-bold text-blue-900">120</span>
          </div>
          <div className="flex flex-col items-center p-6 bg-green-100 rounded-lg shadow-lg mr-10">
            <span className="text-lg font-medium text-green-800">Pagamentos Hoje</span>
            <span className="mt-2 text-4xl font-bold text-green-900">15</span>
          </div>
          <div className="flex flex-col items-center p-6 bg-red-100 rounded-lg shadow-lg">
            <span className="text-lg font-medium text-red-800">Tickets Abertos</span>
            <span className="mt-2 text-4xl font-bold text-red-900">3</span>
          </div>
        </div>


        {/* Gráfico de histórico de pagamentos */}
        <div className="bg-white p-6 rounded-lg shadow mb-8">
          <div className="h-64">
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>

        {/* Últimos Tickets e E-mails Enviados */}
        <div className="grid grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-4">Últimos Tickets Abertos</h3>
            <ul className="space-y-3">
              <li className="border-b pb-2">
                Ticket #1024 - Problema com pagamento
              </li>
              <li className="border-b pb-2">
                Ticket #1023 - Dúvida sobre assinatura
              </li>
              <li className="pb-2">
                Ticket #1022 - Cancelamento de serviço
              </li>
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow hover:shadow-lg transition-shadow">
            <h3 className="text-xl font-semibold mb-4">Últimos E-mails Enviados</h3>
            <ul className="space-y-3">
              <li className="border-b pb-2">
                Email para cliente1@example.com - Confirmação de pagamento
              </li>
              <li className="border-b pb-2">
                Email para cliente2@example.com - Bem-vindo
              </li>
              <li className="pb-2">
                Email para cliente3@example.com - Aviso de renovação
              </li>
            </ul>
          </div>
        </div>

      </div>
      
    </div>
  );
};

export default Home;
