import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a pedidos.
 */
const OrderService = {
  /**
   * Obtém os pedidos de um cliente pelo ID do cliente.
   *
   * @async
   * @function getOrdersByCustomerId
   * @param {number|string} customerId - ID do cliente cujos pedidos serão consultados.
   * @returns {Promise<Object>} - Retorna um objeto com os dados dos pedidos.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getOrdersByCustomerId: async (customerId) => {
    try {
      const response = await HttpClient.get(`/orders?customerId=${customerId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em OrderService.getOrdersByCustomerId:', error);
      throw error;
    }
  },

  /**
   * Obtém uma lista de pedidos com base nos parâmetros fornecidos.
   *
   * @async
   * @function getOrders
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de pedidos a serem retornados por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos pedidos e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getOrders: async ({ limit, page, orderBy, orderDirection }) => {
    let url = `/orders`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy != null) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection != null) queryParams.push(`orderDirection=${orderDirection}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em OrderService.getOrders:', error);
      throw error;
    }
  },

  /**
   * Cria um novo pedido no sistema.
   *
   * @async
   * @function createOrder
   * @param {Object} orderData - Dados do pedido a ser criado.
   * @returns {Promise<Object>} - Retorna os dados do pedido criado.
   * @throws {Error} - Lança um erro se a criação do pedido falhar.
   */
  createOrder: async (orderData) => {
    try {
      const response = await HttpClient.post("/orders", orderData);
      return response.data;
    } catch (error) {
      console.error('Erro em OrderService.createOrder:', error);
      throw error;
    }
  },

  /**
   * Busca os detalhes de um pedido específico pelo ID.
   *
   * @async
   * @function orderById
   * @param {number|string} orderId - ID do pedido.
   * @returns {Promise<Object>} - Retorna os dados do pedido.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  orderById: async (orderId) => {
    try {
      const response = await HttpClient.get(`/orders/${orderId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em OrderService.orderById:', error);
      throw error;
    }
  },

  /**
   * Obtém os itens de um pedido específico pelo ID do pedido.
   *
   * @async
   * @function orderItems
   * @param {number|string} orderId - ID do pedido.
   * @returns {Promise<Object>} - Retorna uma lista de itens do pedido.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  orderItems: async (orderId) => {
    try {
      const response = await HttpClient.get(`/orders/${orderId}/items`);
      return response.data;
    } catch (error) {
      console.error('Erro em OrderService.orderItems:', error);
      throw error;
    }
  }
};

export default OrderService;
