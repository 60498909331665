import Badge from "../../../components/Badge";
import Button from "../../../components/Base/Button";
import Lucide from "../../../components/Base/Lucide";
import Modal from "../../../components/Base/Modal";
import Table from "../../../components/Base/Table";
import OrderService from "../Services/OrderService";
import CustomerService from "../../Customers/Services/CustomerService";
import InvoiceService from "../../Invoices/Services/InvoiceService";
import { formatDate, formatToBRL } from "../../../utils/helpers";
import axios from "axios";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const periodicals = [
  "free",
  "one_time",
  "monthly",
  "quarterly",
  "semiannual",
  "yearly",
  "biennial",
  "triennially",
];

export const periodicals_br = [
  "Gratuito",
  "Única vez",
  "Mensal",
  "Trimestral",
  "Semestral",
  "Anual",
  "Bienal",
  "Trienal",
];

const orderStatus = {
  Pendente: <Badge type="warning" text="Pendente" />,
  Aprovado: <Badge type="success" text="Aprovado" />,
  Cancelado: <Badge type="dark" text="Cancelado" />,
};

const orderProductStatus = {
  Pendente: <Badge type="warning" text="Pendente" />,
  Ativo: <Badge type="success" text="Ativo" />,
  Completo: <Badge type="info" text="Completo" />,
  Suspenso: <Badge type="suspended" text="Suspenso" />,
  Cancelado: <Badge type="dark" text="Cancelado" />,
};

const FooterModal = ({ setOrders, order, closeModal }) => {
  return (
    <div className="flex items-center justify-end gap-2">
      <Button
        variant="success"
        size="sm"
        className={clsx([
          "text-white",
          order.status !== "Pendente" && "cursor-not-allowed opacity-80",
        ])}
        onClick={() => {
          if (order.status !== "Pendente") {
            toast.warning("Este pedido não pode ser aceito, pois foi cancelado.");
            return;
          }
          Swal.fire({
            title: "Você confirma a aprovação deste pedido?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3E97FF",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim, confirmar!",
            preConfirm: async () => {
              Swal.getCancelButton()?.remove();
              try {
                Swal.showLoading();
                await OrderService.approve(order.id);
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  toast.error(e.response?.data.error.message);
                } else {
                  toast.error("Ocorreu algum erro!");
                }
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              closeModal();
              Swal.fire({
                title: "Pedido aprovado com sucesso!",
                icon: "success",
                confirmButtonColor: "#3E97FF",
              });
            }
          });
        }}
      >
        Aceitar pedido
      </Button>

      <Button
        variant="danger"
        size="sm"
        onClick={() => {
          Swal.fire({
            title: "Você tem certeza que deseja deletar este pedido?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3E97FF",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim, deletar!",
            preConfirm: async () => {
              Swal.getCancelButton()?.remove();
              try {
                Swal.showLoading();
                await OrderService.delete(order.id);
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  toast.error(e.response?.data.error.message);
                } else {
                  toast.error("Ocorreu algum erro!");
                }
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              setOrders((oldOrders) => {
                if (!oldOrders?.data) return oldOrders;
                const orders = [...oldOrders.data];
                const idx = orders.findIndex((o) => o.id === order.id);
                if (idx !== -1) {
                  orders.splice(idx, 1);
                }
                return { ...oldOrders, data: orders };
              });
              closeModal();
              Swal.fire({
                title: "Pedido deletado com sucesso!",
                icon: "success",
                confirmButtonColor: "#3E97FF",
              });
            }
          });
        }}
      >
        Deletar pedido
      </Button>

      <Button
        variant="dark"
        size="sm"
        className={clsx([
          order.status === "Cancelado" && "cursor-not-allowed opacity-40",
        ])}
        onClick={() => {
          if (order.status === "Cancelado") {
            toast.warning("Este pedido já se encontra com o status cancelado.");
            return;
          }
          Swal.fire({
            title: "Você tem certeza que deseja cancelar este pedido?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3E97FF",
            cancelButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Sim, cancelar!",
            preConfirm: async () => {
              Swal.getCancelButton()?.remove();
              try {
                Swal.showLoading();
                await OrderService.cancel(order.id);
              } catch (e) {
                if (axios.isAxiosError(e)) {
                  toast.error(e.response?.data.error.message);
                } else {
                  toast.error("Ocorreu algum erro!");
                }
              }
            },
            allowOutsideClick: () => !Swal.isLoading(),
          }).then((result) => {
            if (result.isConfirmed) {
              closeModal();
              Swal.fire({
                title: "Pedido cancelado com sucesso!",
                icon: "success",
                confirmButtonColor: "#3E97FF",
              });
            }
          });
        }}
      >
        Cancelar pedido
      </Button>
    </div>
  );
};

const ProductsOrderModal = ({ idOrder, open, setOrders, closeModal }) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!idOrder || idOrder === 0) {
      setIsLoading(false);
      return;
    }

    const fetchOrder = async () => {
      try {
        setIsLoading(true);
        const orderResponse = await OrderService.orderById(idOrder);
        const orderItemsResponse = await OrderService.orderItems(idOrder);
        const InvoiceResponse = await InvoiceService.invoiceByOrderId(idOrder);
        const customerResponse = await CustomerService.getCustomerById(orderResponse.data.customer_id);

        console.log(orderResponse);

        setOrder({
          ...orderResponse.data,
          items: orderItemsResponse.data,
          customer: customerResponse.data,
          invoice: InvoiceResponse.data
        });
      } catch (e) {
        closeModal();
        Swal.fire({
          title: `Pedido #${idOrder} não encontrado!`,
          icon: "error",
          confirmButtonColor: "#3E97FF",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idOrder]);

  return (
    <Modal
      open={open}
      title={`Pedido - #${idOrder}`}
      width={1100}
      isLoading={isLoading}
      closeModal={closeModal}
      footer={
        !isLoading ? (
          <FooterModal setOrders={setOrders} closeModal={closeModal} order={order} />
        ) : null
      }
    >
      {isLoading ? (
        <div className="p-5 flex justify-center items-center">
          <span>Carregando dados...</span>
        </div>
      ) : (
        <section>
          <h6 className="font-nunito font-semibold text-lg text-slate-500 mb-3">
            Detalhes do pedido
          </h6>

          <div>
            <ul className="grid grid-cols-12 font-nunito font-medium text-sm leading-[18px] text-[#A1A5B7] overflow-hidden gap-[12px] md:gap-0">
              <li className="flex flex-col gap-[12px] col-span-12 md:col-span-6 md:border-r mr-10">
                <div className="flex">
                  <span className="min-w-[182px] ">Cliente/Empresa:</span>
                  <Link
                    to={`/clientes/${order?.customer_id}`}
                    className="text-primary flex items-center translate-y-[-1px]"
                  >
                    <Lucide className="mr-[2px]" icon="ExternalLink" />
                    <p className="translate-y-[1px]">
                      #{order?.customer_id} / {order?.customer?.name}
                    </p>
                  </Link>
                </div>
                <div className="flex">
                  <span className="min-w-[182px] ">Data e horário:</span>
                  <span className="text-[#3F4254]">
                    {order?.created_at &&
                      formatDate(order.created_at, "DD/MM/YYYY HH[h]mm[m]")}
                  </span>
                </div>
                <div className="flex">
                  <span className="min-w-[182px] ">Método de Pagamento:</span>
                  <span className="text-[#3F4254]">
                    {order?.payment_method?.description}
                  </span>
                </div>
              </li>

              <li className="flex flex-col gap-[12px] col-span-12 md:col-span-6">
                <div className="flex">
                  <span className="min-w-[182px] ">Total:</span>
                  <span className="text-[#3F4254]">
                    {order?.items &&
                      formatToBRL(
                        order.items.reduce((acc, item) => acc + Number(item.price), 0)
                      )}
                  </span>
                </div>
                <div className="flex">
                  <span className="min-w-[182px] ">Fatura:</span>
                  <Link
                    to={`/invoice/${order?.invoice?.invoice_id}`}
                    className="text-primary flex items-center translate-y-[-1px]"
                  >
                    <Lucide className="mr-[2px]" icon="ExternalLink" />
                    <p className="translate-y-[1px]">{order?.invoice_id}</p>
                  </Link>
                </div>
                <div className="flex">
                  <span className="min-w-[182px] ">Status: </span>
                  {order?.status && orderStatus[order.status]}
                </div>
              </li>
            </ul>
          </div>

          <h6 className="font-nunito font-semibold text-lg text-slate-500 mt-5 mb-4">
            Produtos/Serviços do pedido
          </h6>
          <section className="overflow-x-scroll xl:overflow-hidden">
            <Table hover className="bord">
              <Table.Thead>
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2 rounded-2xl">
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito rounded-l font-semibold whitespace-nowrap">
                    Produto/Serviço
                  </Table.Th>
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito font-semibold whitespace-nowrap">
                    Descrição
                  </Table.Th>
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito font-semibold whitespace-nowrap">
                    Status
                  </Table.Th>
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito font-semibold whitespace-nowrap">
                    Status do pagamento
                  </Table.Th>
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito font-semibold whitespace-nowrap">
                    Preço
                  </Table.Th>
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito rounded-r font-semibold whitespace-nowrap">
                    Ciclo de pagamento
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody className="before:content-['@'] before:block before:leading-[8px] before:indent-0 before:opacity-0 max-h-[200px] overflow-y-scroll">
                {order?.items?.map((item) => (
                  <Table.Tr key={item.id} className="intro-x">
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {item.id}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] w-full text-[14px] font-normal">
                      {item.product?.name}
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {orderProductStatus[item.status]}
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {formatToBRL(Number(item.price))}
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {
                        periodicals_br[
                          periodicals.findIndex((p) => p === item.payment_cycle)
                        ]
                      }
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          </section>
        </section>
      )}
    </Modal>
  );
};

export default ProductsOrderModal;
